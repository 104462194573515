<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('list.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t('list.entries') }}</label>
          </b-col>
          <b-col
            cols="12"
            md="8"
            class="d-flex justify-content-start mb-1 mb-md-0">
            <b-form-input
              v-model="query"
              class="d-inline-block mr-1"
              :placeholder="$t('table.search.search')" />
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click="export2excel">
              <span class="text-nowrap">{{ $t('list.export') }}</span>
            </b-button>
            <router-link :to="{ name: 'apps-users-edit' }">
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1">
                <span class="text-nowrap">{{ $t('user.add') }}</span>
              </b-button>
            </router-link>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="identity"
        show-empty
        :empty-text="$t('user.search.noMatchingRecords')">
        <!-- column: username -->
        <template #cell(username)="data">
          <b-badge
            pill
            class="text-capitalize">
            {{ data.item.username }}
          </b-badge>
        </template>
        <!-- Column: names -->
        <template #cell(firt_name)="data">
          {{ data.item.firt_name }} {{ data.item.middle_name }}
        </template>
        <!-- Column: surnames -->
        <template #cell(surname_1)="data">
          {{ data.item.surname_1 }} {{ data.item.surname_2 }}
        </template>
        <!-- Column: job_title -->
        <template #cell(job_title)="data">
          {{ getJobTitle(data.item) }}
        </template>
        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize">
            {{ resolveUserStatusMessage(data.item.status) }}
          </b-badge>
        </template>
        <!-- Column: actions -->
        <template #cell(actions)="data">
          <b-col class="flex flex-row flex-wrap">
            <b-button
              :id="`view-button-${data.item.id}`"
              style="margin: 1px"
              variant="primary"
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
            </b-button>
            <b-button
              :id="`edit-button-${data.item.id}`"
              style="margin: 1px"
              variant="primary"
              :to="{ name: 'apps-users-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
            </b-button>
            <!-- ? DETAILS -->
            <b-tooltip
              :target="`view-button-${data.item.id}`"
              :title="$t('actions.details')"
              triggers="hover"
              :noninteractive="true"
              placement="top">
            </b-tooltip>
            <!-- ? EDIT -->
            <b-tooltip
              :target="`edit-button-${data.item.id}`"
              :title="$t('actions.edit')"
              triggers="hover"
              :noninteractive="true"
              placement="top">
            </b-tooltip>
          </b-col>
        </template>
      </b-table>
      <!-- Pagination -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="6"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="total"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item">
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18" />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <div>
      <b-modal
        id="modal-1"
        hide-footer
        :title="$t('actions.delete')">
        <template>
          <delete-confirmation
            :value-name="currentSelection.email"
            :resources-element-name="RESOURCES_ELEMENT_NAME"
            class="mt-2 pt-75" />
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import * as XLSX from 'xlsx'
import DeleteConfirmation from '../../core/DeleteConfirmation.vue'
import Filters from './filters.vue'
import UserStoreModule from '../storeModule'

export default {
  components: {
    DeleteConfirmation,
    Filters,
    BTooltip,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      items: [],
      total: 1,
      perPage: 5,
      currentPage: 1,
      currentPath: window.location.pathname,
      perPageOptions: [5, 10, 25, 50, 100],
      query: '',
      tableColumns: [
        { key: 'username', label: this.$t('user.username'), sortable: true },
        { key: 'firt_name', label: this.$t('user.names'), sortable: true },
        { key: 'surname_1', label: this.$t('user.surnames'), sortable: true },
        {
          key: 'document_type_value',
          label: this.$t('user.idType'),
          sortable: true,
        },
        { key: 'document', label: this.$t('user.identity'), sortable: true },
        {
          key: 'job_title_value',
          label: this.$t('user.jobTitle'),
          sortable: true,
        },
        { key: 'email', label: this.$t('user.email'), sortable: true },
        { key: 'status', label: this.$t('user.status'), sortable: true },
        {
          key: 'actions',
          label: this.$t('actions.tittle'),
          thStyle: 'width: 300px;',
        },
      ],
      currentSelection: {},
      sheets: [{ name: 'SheetOne', data: [{ c: 2 }] }],
      USER_APP_STORE_MODULE_NAME: 'app-user',
      RESOURCES_ELEMENT_NAME: 'user',
    }
  },
  watch: {
    perPage(val) {
      this.fetchUsers()
    },
    currentPage(val) {
      this.fetchUsers()
    },
    query(val) {
      this.currentPage = 1
      if (val.length >= 3 || val.length == 0) this.fetchUsers()
    },
  },
  mounted() {
    // Register module
    if (!store.hasModule(this.USER_APP_STORE_MODULE_NAME))
      store.registerModule(this.USER_APP_STORE_MODULE_NAME, UserStoreModule)
    var data = JSON.parse(sessionStorage.getItem(this.currentPath))

    data && (this.query = data.query)
    this.fetchUsers()

    this.$root.$on('delete-entity', () => {
      const _self = this
      if (_self.currentSelection.id) {
        console.log(_self.currentSelection.id)
        store
          .dispatch(`${this.USER_APP_STORE_MODULE_NAME}/delete_element`, {
            id: _self.currentSelection.id,
          })
          .then((response) => {
            _self.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: _self.$t('actions.result.delete.title'),
                icon: 'SaveIcon',
                variant: 'success',
                text: _self.$t('actions.result.delete.success'),
              },
            })
            _self.fetchUsers()
          })
          .catch((error) => {
            _self.fetchUsers()
            // _self.$toast({
            //   component: ToastificationContent,
            //   position: 'top-right',
            //   props: {
            //     title: _self.$t('actions.result.delete.title'),
            //     icon: 'SaveIcon',
            //     variant: 'danger',
            //     text: _self.$t('actions.result.delete.failure'),
            //   },
            // })
          })
      }
    })
  },
  beforeDestroy() {
    this.$root.$off('delete-entity')
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.USER_APP_STORE_MODULE_NAME))
      store.unregisterModule(this.USER_APP_STORE_MODULE_NAME)
  },
  methods: {
    filterJson(jsonData) {
      const filterList = this.tableColumns.map((a) => a.key)
      filterList.pop()
      return jsonData.map((v) => filterList.map((j) => v[j]))
    },
    getHeader() {
      const res = {}
      for (let i = 0; i < this.tableColumns.length; i++) {
        res[this.tableColumns[i].key] = this.tableColumns[i].label
      }
      return res
    },
    format(jsonData) {
      for (let i = 0; i < jsonData.length; i++) {
        jsonData[i].status = this.resolveUserStatusMessage(jsonData[i].status)
      }
    },
    async export2excel() {
      const filename = this.$t(`${this.RESOURCES_ELEMENT_NAME}.object_name`)
      const result = await store.dispatch('app-user/fetchUsers', {
        perPage: 10000,
        page: 1,
        query: this.query,
      })
      this.format(result.data.items)
      result.data.items.unshift(this.getHeader())
      const list = this.filterJson(result.data.items)
      const data = XLSX.utils.json_to_sheet(list, { skipHeader: true })
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, data, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },
    beforeGenerate() {
      console.info('test')
    },
    getJobTitle(user) {
      return user.job_title ? user.job_title.value : ''
    },
    fetchUsers() {
      sessionStorage.setItem(
        this.currentPath,
        JSON.stringify({ query: this.query })
      )

      store
        .dispatch('app-user/fetchUsers', {
          perPage: this.perPage,
          page: this.currentPage,
          query: this.query,
        })
        .then((response) => {
          this.items = response.data.items
          this.total = response.data.total
        })
        .catch(() => {})
    },
    resolveUserStatusVariant(status) {
      if (status === 0) return 'warning'
      return 'success'
    },
    resolveUserStatusMessage(status) {
      if (status === 0) return this.$t('user.statusValue.inactive')
      return this.$t('user.statusValue.active')
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
